import React from "react"
import { Link,graphql,useStaticQuery } from "gatsby";
import { FiGithub,FiMail,FiLinkedin, FiInstagram } from 'react-icons/fi';

import Layout from "../components/layout"
import SocialButton from "../components/socialButton"
import Head from "../components/head"

import iconStyles from "../styles/footer.module.css"
import pageStyles from "../styles/page.module.css"

export default function Home() {
  const data = useStaticQuery(graphql`
    query{
        site{
            siteMetadata{
               github,linkedin,mail,author,instagram
            }
        }
    }
    
    `)
  return <Layout>
    <Head title="DeveloperYJ"/>
    <div>
    <SocialButton>
        <Link to={data.site.siteMetadata.github} target="_blank" className={iconStyles.icon} ><FiGithub size="2.3rem" title="Github" color="rgb(34,31,32" className={iconStyles.icon} /></Link>
    </SocialButton>
      <SocialButton>
        <Link to={data.site.siteMetadata.linkedin} target="_blank"><FiLinkedin size="2.3rem" title="Linkedin" color="rgb(34,31,32)"/></Link>
      </SocialButton>
      <SocialButton>
        <Link href={"mailto:" + data.site.siteMetadata.mail} style={{textDecoration:"none"}} ><FiMail size="2.3rem" title="Mail" color="rgb(34,31,32)"/></Link>
      </SocialButton>
      <SocialButton>
        <Link to={data.site.siteMetadata.instagram} target="_blank"><FiInstagram size="2.3rem" title="Instagram" color="rgb(34,31,32)" /></Link>
      </SocialButton>
    </div>
    <Link to="/about" className={pageStyles.pages}>About</Link> 
    <Link to="/work" className={pageStyles.pages}>Work</Link> 
    </Layout>
   
}

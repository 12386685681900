import React from "react"
import Footer from "./footer.js"
import Header from "./header.js"
import layoutStyles from "../styles/layout.module.css"
// import '../styles/index.css'

export default function Layout(props){
    return <div>
    <div className={layoutStyles.layout}>
<Header/>
{props.children}

    </div>
        <Footer />
    </div>
}

import React from "react"
import { Link} from 'gatsby';
import footerStyles from "../styles/footer.module.css"

export default function Header(){

    return <div >
   {/* <Link to="/">Yash Jajoo</Link>  */}
        <Link to="/" style={{textDecoration:"none"}}><p className={footerStyles.header}>DeveloperYJ</p></Link>
        
        <p className={footerStyles.subHeader}>I develop, make and figure out things</p>
    </div>
}
import React from "react"


import iconStyles from "../styles/icons.module.css"



export default function SocialButton(props) {

    return <div className={iconStyles.icon}>

        {props.children}
    </div>
}